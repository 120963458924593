import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { v4 } from 'uuid'
import PhoneIcon from '../Icons/PhoneIcon'
import WidthIcon from '../../images/width.svg'
import HeightIcon from '../../images/height.svg'
import LenthIcon from '../../images/lenth.svg'
import ViewIcon from '../Icons/ViewIcon'
import { Badge} from "../section"
import Img from 'gatsby-image'
import DefaultButton from "../button/DefaultButton"
import DefaultOutlineButton from "../button/DefaultOutlineButton"
import BreakpointUp from "../Media/BreakpointUp"
import BreakpointDown from "../Media/BreakpointDown"

const Card = styled.div`
	margin-bottom:30px;
	display:flex;
	flex-wrap: wrap;
	background: #fff;	
	box-shadow: 0px 12px 32px rgba(0,0,0,0.2);
	position:relative;	
	${BreakpointDown.lg`				
		flex-direction: column-reverse;		
	`}
`
const CardTitle = styled(Link)`
	margin:0 0 5px;
	color:#020304;
	font-size:20px;
	line-height:24px;
	font-weight:700;

`
const CardFigure = styled.div`
	position:relative;
	width: 100%;
	& .gatsby-image-wrapper{
		height:100%;
	}
	${BreakpointUp.lg`				
		width: 55%;	
	`}
	& .gatsby-image-wrapper{
		img {
			object-fit: inherit !important;
		}
	}
`
const CardContent = styled.div`	
	padding: 35px 15px;
	position:relative;
	z-index:1;
	width: 100%;
	${BreakpointUp.lg`				
		width: 45%;	
		padding: 35px;
	`}
	${BreakpointDown.sm`			
		padding: 60px 15px 35px 15px;
		text-align:center;
	`}
`
const CardOption = styled.ul`
	margin:0 -15px;	
    list-style: none;    
	padding: 0;
	${BreakpointDown.sm`
		font-size:14px;
	`}
`

const CardOptionItem = styled.li`
	display:inline-block;
	font-size: 13px;
	margin:0 15px;
	color:#666666;
	font-weight:500;	
	position:relative;
	&:before{
		display: inline-block;
		content: '';
		width: 6px;
		height: 6px;
		background-color: #676B79;
		border-radius: 50%;
		position: absolute;
		left: -20px;
		top: 50%;
		transform: translateY(-50%);
	}
	&:first-child{
		&:before{
			display:none;
		}
	}
`

const HelpBlock = styled.p`
	font-size: 12px;
	color: #999;
	margin: 5px 0 30px;
	line-height:22px;
	${BreakpointUp.sm`
		font-size: 14px;
	`}
`
const PriceBlock = styled.div`
	border-top:1px solid #DDD;
	padding-top:30px;
	margin-top:30px;
	display: flex;
    align-items: center;    
	& small{
		display:block;
		font-size:14px;
		color:#999;
	}
	${BreakpointDown.sm`			
		justify-content:center;
		padding-top:20px;
		margin-top:20px;
	`}
`
const TotalPrice = styled.div`
	& strong{
		font-family: 'IBM Plex Serif', serif;      
		font-weight:700;
		font-size:28px;
		color:#00A21E;
	}

`
const DepositPrice = styled.div`
	margin-left:40px;
	& strong{
		font-family: 'IBM Plex Serif', serif;      
		font-weight:700;
		font-size:22px;
		color:#666;
	}
`
const ButtonToolbar = styled.div`
	display: flex;
	flex-wrap:wrap;
	margin:0 -5px;	
	&.show-desktop{
		display:none;
		@media (min-width: 576px) {
			display:flex;
		}
	}
	&.show-mobile{
		display:flex;
		@media (min-width: 576px) { 			
			display:none;
		}
	}
	.btn-item{
		position:relative;
		padding:0 5px;
		@media(max-width: 575.98px) {	
			width:100%;
			.btn{
				display:block;
			}
			+ .btn-item{
				margin-top:15px
			}
		}	
	}
	& a{
		& .btn{
			${BreakpointDown.lg`
				padding: 12px 16px;
			`}
		}
	}
	
`
const ProductSize = styled.div`
	background-color:#fff;
	border:1px solid #ddd;
	position:absolute;
	display:block;
	transform:translateX(-50%);		
	right:auto;
	bottom:-35px;
	left:50%;
	width:100%;
	max-width: 300px;
	${BreakpointUp.sm`
		max-width:80px;
		transform:translateY(-50%);		
		bottom:auto;
		left:auto;
		top:50%;
		right:0;
		
	`}
	${BreakpointUp.sm`
		right:-40px;
	`}

`

const ProductItemSize = styled.div`
	background-color:#fff;	
	width:98px;
	height:60px;
	padding:8px 15px;
	border-left:1px solid #ddd;
	border-top:none;
	display:inline-block;
	text-align: center;
	&:first-child{
		border-left:none;
	}
	${BreakpointUp.sm`	
		display:block;
		width:78px;
		height:70px;
		padding:12px 15px;
		border-top:1px solid #ddd;
		border-left:none;
		&:first-child{
			border-top:none;
		}
	`}
	.icon{
		height:28px;
		display: flex;
		align-items: flex-end;
		margin-bottom: 2px;	
		justify-content: center;
	}
	.text{
		display:block;
		font-size:12px;
		color:#666;
		font-weight:500;
		white-space: nowrap;
		line-height:20px;
		b{
			font-weight:700;
			color:#020304;
		}
	}
	
`
const CardSku = styled.div`
	display: block;
	margin-bottom: 10px;	
	background-color: #020304;
	color: #fff;
	padding: 4px 10px;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;  
	text-transform: uppercase;
	width:fit-content;
	${BreakpointDown.sm`			
		margin:0 auto 10px auto;
	`}
`

const CardBadge = styled(Badge)`
  bottom: 30px;
	top: auto;
`;

const ProductCard = ({ data, showQuote }) => {
	const productURL = (data.productCategory.link + "/" + data.url).replace('//', '/');
	const productID =  data.id.slice(-12);
	return(						
		<Card className="card">
			
			<CardContent className="card-body">
				<CardSku>SKU - {productID}</CardSku>
				<CardTitle to={productURL}>{data.productName}</CardTitle>
				<CardOption>
					{ data.featuresImage && (
						data.featuresImage.map(item => {
							return(
								<CardOptionItem key={v4()}>{item.title}</CardOptionItem>			
							)
						})
					)}
				</CardOption>
				<PriceBlock>
					<TotalPrice><strong>${data.totalPrice}</strong><small>Starting Price</small></TotalPrice>
					{/* {data.depositPrice?(
						<DepositPrice><strong>${data.depositPrice}</strong><small>Down Payment</small></DepositPrice>	
					):(
						<></>
					)}		 */}
				</PriceBlock>
				{data.priceDescription && <HelpBlock>{data.priceDescription}</HelpBlock>}
				<ButtonToolbar>
					<a className="btn-item" href="tel:8776005980"><DefaultOutlineButton icon={<PhoneIcon />} text="(877) 600-5980" /></a>
					<Link className="btn-item" to={productURL}><DefaultButton icon={<ViewIcon />} text="View Details" /></Link>
				</ButtonToolbar>
				{/* <ButtonToolbar className="show-desktop">
					<div className="btn-item" onClick={showQuote} ><DefaultOutlineButton icon={<QouteIcon />} text="Contact Us" /></div>
					<div className="btn-item" onClick={showQuote} ><DefaultButton icon={<QouteIcon />} text="Get a Quote" /></div>
					<Link className="btn-item" to={productURL}><DefaultButton text="More Info" /></Link>
				</ButtonToolbar> */}
			</CardContent>
			<CardFigure className="card-img">
				{data.popularLabel==="Good"?(<CardBadge color="#DE5600" bgColor="#f8ddcc">{data.popularLabel}</CardBadge>):(<></>)}
				{data.popularLabel==="Better"?(<CardBadge color="#216ABC" bgColor="#d3e1f2">{data.popularLabel}</CardBadge>):(<></>)}
				{data.popularLabel==="Best"?(<CardBadge color="#2F9800" bgColor="#d5eacc">{data.popularLabel}</CardBadge>):(<></>)}
				{data.image && (
					<Img fluid={data.image.fluid} />
				)}
				<ProductSize className="measurement">
					<ProductItemSize>
						<span className="icon"><img src={WidthIcon} alt="width" /></span>
						<span className="text">Width <b>{data.width}</b></span>
					</ProductItemSize>
					<ProductItemSize>
						<span className="icon"><img src={LenthIcon} alt="length" /></span>
						<span className="text">Length <b>{data.length}</b></span>
					</ProductItemSize>
					<ProductItemSize>
						<span className="icon"><img src={HeightIcon} alt="height" /></span>
						<span className="text">Height <b>{data.height}</b></span>
					</ProductItemSize>
				</ProductSize>
			</CardFigure>
		</Card>
	)
}


export default ProductCard
